import request from '@/utils/request'

export function getCertificateById(data) {
  return request({
    url: `/api/Certificates/GetCertificateById/${data}`,
    method: 'get',
  })
}

export function getCertificates() {
  return request({
    url: '/api/Certificates/GetCertificates',
    method: 'get',
  })
}

export function addCertificate(data) {
  return request({
    url: '/api/Certificates/AddCertificate',
    method: 'post',
    data,
  })
}

export function updateCertificate(data) {
  return request({
    url: '/api/Certificates/UpdateCertificate',
    method: 'put',
    data,
  })
}
export function deleteCertificate(data) {
  return request({
    url: `/api/Certificates/DeleteCertificate?firmRequestCertificatedId=${data}`,
    method: 'delete',
  })
}
